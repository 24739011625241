




















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import { datatypes } from "@/components/DataTableGenerico/datatypes";
import tipos_fichajeModule from "@/store/modules/tipos_fichaje-module";
import { tipos_fichaje } from "@/shared/dtos/tipos_fichaje";

@Component({
  components: {},
})
export default class tipos_fichajeFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: tipos_fichaje = new tipos_fichaje();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await tipos_fichajeModule.gettipos_fichaje(this.getparamId().Parametro);
      this.item_pantalla = tipos_fichajeModule.tipos_fichaje;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 6);

  }

  public Insertar(object: tipos_fichaje) {
    tipos_fichajeModule.guardartipos_fichaje(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: tipos_fichaje) {
    tipos_fichajeModule.modificartipos_fichaje(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
